* {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
}

body {
  background-color: #fbfbfb;
  font:
    14px Arial,
    Helvetica,
    sans-serif;
}

.grecaptcha-badge {
  display: none !important;
}

#root {
  position: fixed;
  width: 100%;
  height: 100%;
}

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.topBarDiv {
  display: table-row;
}

.topBar {
  width: 100%;
  height: 52px;
  top: 0px;
  left: 0px;
  z-index: -5;
  color: #fbfbfb;
  background-color: #527dd6;
  border-radius: 4px;
}

.contenido {
  display: table-row;
  height: auto;
  overflow-y: auto;
}

.iconCell {
  width: 52px;
  overflow: hidden;
  word-wrap: break-word;
  text-align: center;
  border: none;
}

.inicio {
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  background-color: #c3cce9;
  cursor: pointer;
}

.inicio_titulo {
  text-align: center;
  font-family: 'Caveat', serif;
  font-size: 36px;
}
.inicio_contenido {
  font:
    12px Arial,
    Helvetica,
    sans-serif;
  text-align: justify;
  padding-left: 12px;
  padding-right: 12px;
  overflow-y: auto;
}

.headerButton {
  width: 48px;
  height: 48px;
  background-color: #527dd6;
  border-width: 0px;
}

.svgIcon {
  fill: white;
  cursor: pointer;
  padding-left: 10px;
  padding-right: -10px;
}

.svgIcon:hover {
  fill: silver;
  transform: scale(1.1);
}

.searchForm {
  display: flex; /* Para alinear el input y el botón horizontalmente */
  align-items: center; /* Alinear verticalmente */
  position: relative; /* Asegura que el botón esté dentro del contenedor */
}

.searchInput {
  flex: 1; /* Toma todo el espacio disponible */
  border-radius: 4px;
  border: 1px solid #ccc;
  width: auto; /* Cambia de width: 94% para que flexbox lo maneje */
  height: 32px;
  z-index: 5;
  opacity: 0.7;
  padding-left: 10px;
  font:
    16px Arial,
    Helvetica,
    sans-serif;
    color: black;
}

.searchInput:focus {
  opacity: 1;
  outline: none; /* Opcional: quitar el borde azul del navegador */
}

.svgClear {
  margin-right: 4px;
  margin-left: 4px;
  cursor: inherit;
  fill: white;
  cursor: pointer;
}

.svgClear:hover {
  fill: silver;
}

/* Asycronios Wait */
.unlockScreen {
  position: absolute;
  visibility: hidden;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(64, 64, 64, 0.3);
}

.lockScreen {
  position: absolute;
  visibility: visible;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 20;
  background: rgba(64, 64, 64, 0.3);
  text-align: center;
}

.waitingDivCom {
  text-align: center;
  padding: 0;
  background-color: #c3cce9;
}

.waitingImg {
  max-width: 100px;
  display: inline-block;
}

.waitingCom {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

/* Side Menu */
.hamMenuCanvasHide {
  position: absolute;
  visibility: hidden;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #fbfbfb;
  background: rgba(64, 64, 64, 0.3);
}

.tb {
  width: 100%;
  height: 100%;
}

.scapeArea {
  width: 30%;
  height: 100%;
}
.hamMenuCanvasShow {
  position: absolute;
  visibility: visible;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #fbfbfb;
  background: rgba(64, 64, 64, 0.3);
}

.hamMenu {
  position: relative;
  float: left;
  background-color: #457ad3;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  border-right: 1px solid #b4c9ec;
  text-align: left;
}

.hamMenuDiv {
  height: 80px;
  background-color: #3a6dd3;
  color: white;
  border-bottom: 1px solid #b4c9ec;
  padding: 0px 0px 0px 0px;
  font:
    16px Arial,
    Helvetica,
    sans-serif;
}

.hanSpan {
  display: inline-block;
}

.hamMenuSep {
  height: 14px;
  border-bottom: 1px solid #b4c9ec;
}

.hamMenuDiv:hover {
  background-color: #4776d3;
}

.hamMenu a {
  color: white;
  text-decoration: none;
}

.hamMenu a:visited {
  text-decoration: none;
}

.menuIcon {
  fill: white;
  cursor: pointer;
  padding: 0px 20px 0px 20px;
}

.hamMenuDropdown {
  width: 90%;
  padding-left: 0px;
  background-color: transparent;
  color: white;
  font: inherit;
}
/* Errores */

.txtError {
  color: red;
  text-align: center;
  font:
    16px Arial,
    Helvetica,
    sans-serif;
}

.imgError {
  padding: 20px;
  display: block;
  margin: auto;
}
